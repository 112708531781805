<template>
  <div class="DPsummaryWrap">
    <div class="DPsummary content">

      <div class="FundInforWrap">
        <iframe v-if="fileUrl != null" :src="fileUrl" width='100%' height='100%' style="border:none"></iframe>
        <noData v-else :text="$t(`oa.noData`)" />
      </div>
    </div>
  </div>
</template>
<script>
import url from "@/api/url.js";
import noData from "@/components/noData.vue";
export default {
  components: {
    noData,
  },
  props: {
    date: {
      type: String,
      default: () => ""
    },
    fileUrl:{
      type:String,
      default: () => ""
    }
  },
  data() {
    return {
      downLoading: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.DPsummaryWrap{
  width: 100%;
  height:calc(100% - 84px);
}
.DPsummary {
  width: 100%;
  height: 100%;
  
  .left {
    text-align: left;
    color: #333;
  }
  .center {
    text-align: center;
    color: #333;
  }
  .flex1 {
    flex: 1;
  }
  .FundInforWrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    .TableRight,
    .TableLeft {
      width: 40%;
    }
    .table {
      min-width: 20%;
      margin: 0 1%;

      h3 {
        font-size: 20px;
        margin: 10px 0;
      }
      .flexUlTabel {
        display: flex;
        ul {
          flex: 1;
          li {
            padding: 3px 0;
          }
        }
      }
      .titleTable {
        display: flex;
        li {
          color: #305A9E;
          font-weight: bold;
          flex: 1;
          height: 30px;
          line-height: 30px;
          padding: 3px 0;
        }
      }
      .tableContent {
        width: 100%;
        .width100 {
          width: 100px;
        }
        ul {
          li {
            height: 30px;
            line-height: 30px;
          }
        }
        tr,
        th {
          height: 30px;
        }
        th {
          color: #305A9E;
        }
      }
    }
  }
}
</style>